import React, { useMemo } from "react";
import format from "date-fns/format";
import SingleDeal from "./SingleDeal";
// import TableExpanding from "./TableExpanding";
import TableExpandingBackendPagination from "./TableExpandingBackendPagination";
import ButtonNewInvoice from "./ButtonNewInvoice";
import InvoiceStatus from "./InvoiceStatus";
import InvoiceActions from "./InvoiceActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

function formatMoney(number, currency) {
  return (number / 100).toLocaleString("en-US", currency ? { style: "currency", currency: currency } : undefined);
}

function DealsTableExpanding(props) {
  // filterdata by thom
  const filterData = (data) => {
    const withInvoices = data //['deals']
      .map((deal) => {
        const formattedDeal = { ...deal };
        if (deal["invoices"] && deal["invoices"].length >= 1) {
          formattedDeal["invoiceStatus"] = <InvoiceStatus invoices={deal["invoices"]} />;
          formattedDeal["invoiceActions"] = <InvoiceActions invoices={deal["invoices"]} />;
          // console.log("formattedDeal")
          // console.log(formattedDeal)
        } else {
          formattedDeal["invoiceStatus"] = <div>No invoices</div>;
          formattedDeal["invoiceActions"] = <ButtonNewInvoice />;
          // console.log("formatteddeal button")
          // console.log(formattedDeal)
        }
        // Format date
        // formattedDeal['dealDate'] =  formattedDeal['dealDate'].slice().substring(0,10)
        formattedDeal["dealDate"] = formattedDeal["dealDate"] ? format(new Date(formattedDeal["dealDate"]), "yyyy-MM-dd") : "";

        // add currency to price
        if (formattedDeal["pricePerUnit"] !== null && formattedDeal["currencyDeal"] !== null) {
          // formattedDeal["pricePerUnit"] =
          //   formattedDeal["currencyDeal"]["description"].slice() + " " + formattedDeal["pricePerUnit"].slice();
          formattedDeal["pricePerUnit"] = formatMoney(formattedDeal["pricePerUnit"] * 100, formattedDeal["currencyDeal"]["description"]);
        }
        if (formattedDeal["commissionSeller"] !== null && formattedDeal["currencyCommissionSeller"] !== null) {
          formattedDeal["commissionSeller"] = formatMoney(formattedDeal["commissionSeller"] * 100, formattedDeal["currencyCommissionSeller"]["description"]);
        }
        if (formattedDeal["commissionBuyer"] !== null && formattedDeal["currencyCommissionBuyer"] !== null) {
          formattedDeal["commissionBuyer"] = formatMoney(formattedDeal["commissionBuyer"] * 100, formattedDeal["currencyCommissionBuyer"]["description"]);
        }
        return formattedDeal;
      });
    // .filter( (deal) => deal['invoices'].length >= 1 )
    // console.log("WithInvoices");
    // console.log(withInvoices);

    // return data['deals']
    return withInvoices;
  };

  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
        ),
      },
      { Header: "Olyx ref", accessor: "olyxRef" },
      { Header: "Seller", accessor: "seller.companyName" },
      { Header: "Broker", accessor: "brokerSeller.brokerName" },
      { Header: "Buyer", accessor: "buyer.companyName" },
      { Header: "Broker", accessor: "brokerBuyer.brokerName" },
      { Header: "Product", accessor: "product.name" },
      { Header: "Volume", accessor: "volume" },
      { Header: "Unit", accessor: "unit.description" },
      { Header: "Price per Unit", accessor: "pricePerUnit" },
      { Header: "Deal Date", accessor: "dealDate" },
      { Header: "Commission Seller", accessor: "commissionSeller" },
      { Header: "Commission Buyer", accessor: "commissionBuyer" },
      // { Header: 'Invoices Status', accessor: 'invoiceStatus' },
      // { Header: 'Invoices Actions', accessor: 'invoiceActions' },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback((data, rowOriginal) => {
    // const currentDeal = data.filter((deal) => deal.id === rowOriginal.id);
    return <>{rowOriginal && rowOriginal.id && <SingleDeal dealId={rowOriginal.id} />}</>;
  }, []);

  // if (loading) {
  //   return <div>Loading</div>;
  // }
  // if (data) {
  if (props && props.data) {
    return (
      <div>
        <TableExpandingBackendPagination
          renderRowSubComponent={renderRowSubComponent}
          // data={data["dealslist"]}
          data={props.data["dealslist"]}
          columns={columns}
          filterData={filterData}
        />
        {/* <Button onClick={() => fetchMore({ variables: { take: 100 } })}>Load more data</Button> */}
        {/* <Button onClick={() => loadMoreData()}>Load more data</Button> */}
      </div>
    );
  } else {
    return (
      <>
        <div>No data for table</div>
      </>
    );
  }
}

export default DealsTableExpanding;
