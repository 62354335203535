import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import useDeal from "../../hooks/useDeal";
import AddDealModal from "../Modals/AddDealModal";
import { motion } from "framer-motion";
import helpers from "../../services/helpers";
import { AdminToggleProvider } from "../../contexts/AdminToggleContext";

const SingleDeal = (props) => {
  const [data] = useDeal({ dealId: props.dealId?.toString() ?? "0" });
  const [showAddDealModal, setShowAddDealModal] = useState(false);
  const getInitialValues = helpers.getInitialValuesDeal;

  const slashMotion = {
    rest: { scale: 1, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      scale: 1,
      rotate: 20,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  const handleCloseForm = () => {
    setShowAddDealModal(false);
  };

  return (
    <>
      <div style={styles.root}>
        {data && data.deals && (
          <AdminToggleProvider>
            <AddDealModal
              open={showAddDealModal}
              handleClose={() => {
                handleCloseForm();
              }}
              initialValues={getInitialValues(data.deals[0])}
              closeAfterSubmit={() => {
                handleCloseForm();
              }}
            />
            <motion.button
              initial="rest"
              whileHover="hover"
              animate="rest"
              className={
                "rounded ml-4 p-2 text-primary-blue underline underline-offset-4 decoration-1 opacity-90 hover:text-gray-dark text-sm ease-in-out duration-300"
              }
              onClick={() => {
                setShowAddDealModal(true);
              }}
            >
              Update deal information
              <motion.button variants={slashMotion} className="transform">
                <EditIcon className="pb-2" />
              </motion.button>
            </motion.button>
          </AdminToggleProvider>
        )}
      </div>
    </>
  );
};

const styles = {
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    height: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "white",
  },
};
export default SingleDeal;
