import React from "react";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import DateSelect from "./DateSelect";
import AutoCompleteInput from "./AutoCompleteInput";
import TextInput from "./TextInput";
import * as Yup from "yup";

function FilterForm(props) {
  // const [formFields, setFormFields] = useState(props.formFields);
  // useEffect(() => {
  //   setFormField(props.formFields[0]);
  // }, [props]);
  const validationSchema = { after: Yup.date().min("2010-01-01", "Must be in 2010 or later") };

  return (
    <Formik
      initialValues={undefined}
      validationSchema={Yup.object(validationSchema)}
      // enableReinitialize={true}
    >
      <Form>
        <Grid container spacing={2}>
          {props && props.formFields && props.formFields.dateAfter && (
            <DateSelect
              label={props.formFields.dateAfter.label}
              name={props.formFields.dateAfter.name}
              type={props.formFields.dateAfter.fieldType}
              // defaultValue={props.formFields.dateAfter.defaultValue}
              value={props.formFields.dateAfter.value}
              required={props.formFields.dateAfter.required}
              onChange={props.formFields.dateAfter.onChange}
            />
          )}
          {props && props.formFields && props.formFields.dateBefore && (
            <DateSelect
              label={props.formFields.dateBefore.label}
              name={props.formFields.dateBefore.name}
              type={props.formFields.dateBefore.fieldType}
              // defaultValue={props.formFields.dateBefore.defaultValue}
              value={props.formFields.dateBefore.value}
              required={props.formFields.dateBefore.required}
              onChange={props.formFields.dateBefore.onChange}
            />
          )}
          <AutoCompleteInput
            label={props.formFields.broker.label}
            name={props.formFields.broker.name}
            type={props.formFields.broker.fieldType}
            defaultValue={props.formFields.broker.defaultValue}
            value={props.formFields.broker.value}
            required={props.formFields.broker.required}
            onChange={props.formFields.broker.onChange}
            options={props.formFields.broker.options}
          />
          <AutoCompleteInput
            label={props.formFields.dealStatus.label}
            name={props.formFields.dealStatus.name}
            type={props.formFields.dealStatus.fieldType}
            defaultValue={props.formFields.dealStatus.defaultValue}
            value={props.formFields.dealStatus.value}
            required={props.formFields.dealStatus.required}
            onChange={props.formFields.dealStatus.onChange}
            options={props.formFields.dealStatus.options}
          />
          <TextInput
            label={props.formFields.olyxRef.label}
            name={props.formFields.olyxRef.name}
            type={props.formFields.olyxRef.fieldType}
            // defaultValue={props.formFields.dateBefore.defaultValue}
            value={props.formFields.olyxRef.value}
            required={props.formFields.olyxRef.required}
            onChange={props.formFields.olyxRef.onChange}
          />
        </Grid>
      </Form>
    </Formik>
  );
}

export default FilterForm;
