import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";

function AutoCompleteInput(props) {
  const getOptionById = (id) => {
    return props.options.find((option) => option.id === id) || null;
  };

  const getOptionDescription = (optionObject) => {
    return optionObject && optionObject.__typename === "Broker"
      ? optionObject.brokerName
      : optionObject && optionObject.__typename === "DealStatus"
      ? optionObject.description
      : "";
  };

  const currentOptionDescription = getOptionDescription(getOptionById(props.value));

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <AutoComplete
          id={props.name}
          value={props.value}
          fullWidth={true}
          options={props.options.map((option) => option.id)}
          getOptionLabel={(option) => getOptionDescription(getOptionById(option))}
          style={{ maxWidth: 300 }}
          onChange={props.onChange}
          renderInput={(params) => {
            const { inputProps, ...otherParams } = params;

            const modifiedInputProps = {
              ...inputProps,
              value: currentOptionDescription,
              initialValue: currentOptionDescription,
            };

            return (
              <>
                <TextField {...otherParams} inputProps={modifiedInputProps} label={props.label} variant="outlined" fullWidth margin="normal" />
              </>
            );
          }}
          includeInputInList={true}
          autoSelect={true}
          autoComplete={true}
        />
      </Grid>
    </>
  );
}

export default AutoCompleteInput;
